import React, { Component } from 'react'

const data = [
  {
    heading: 'Affordable Pricing Plans',
    headingText:
      'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati dignissimos quae quo ad iste ipsum officiis deleniti asperiores sit.',
    headingTextTwo:
      'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati.',
  },
  {
    image: '/img/project-images/donnan-arena-1.jpeg',
    iconClass: 'icofont-image',
    projectName: 'Donnan Arena parking lot redevelopment',
  },
  {
    image: '/img/project-images/donnan-arena-2.jpeg',
    iconClass: 'icofont-image',
    projectName: 'Donnan Arena parking lot redevelopment',
  },
  {
    image: '/img/project-images/LEEDLrt.jpeg',
    iconClass: 'icofont-image',
    projectName:
      'LEED certified LRT station upgrade implementing deep retrofit strategies',
  },
  {
    image: '/img/project-images/LRTStation.jpeg',
    iconClass: 'icofont-image',
    projectName:
      'Outdoor LRT station mechanical and electrical system upgrades',
  },
  {
    image: '/img/project-images/LRTHub.jpeg',
    iconClass: 'icofont-image',
    projectName: 'Design of LEED certified LRT hub for passengers',
  },
  {
    image: '/img/project-images/RiverValley.jpeg',
    iconClass: 'icofont-image',
    projectName:
      'Project Management of River valley LRT station including mechanical, electrical and track work.',
  },
  {
    image: '/img/project-images/Muttart.jpeg',
    iconClass: 'icofont-image',
    projectName:
      'Feasibility study of CHP installation at Muttart Conservatory',
  },
  {
    image: '/img/project-images/olympic-phase-1.jpeg',
    iconClass: 'icofont-image',
    projectName:
      'Lead Project Manager in Peter Hemingway Phase I and Phase II rehabilitation project upgrading the pool to Olympic standard',
  },
  {
    image: '/img/project-images/olympic-phase-2.jpeg',
    iconClass: 'icofont-image',
    projectName:
      'HVAC System upgrade study from Like for Like replacement to modern equivalent as per the National Energy Code for Buildings 2017',
  },
  {
    image: '/img/project-images/HVAC.jpeg',
    iconClass: 'icofont-image',
    projectName:
      'HVAC System upgrade study from Like for Like replacement to modern equivalent as per the National Energy Code for Buildings 2017',
  },
  {
    image: '/img/project-images/SolarPV.jpeg',
    iconClass: 'icofont-image',
    projectName:
      'Solar PV installation feasibility study in large building with high EUI',
  },
  {
    image: '/img/project-images/blatchford.jpeg',
    iconClass: 'icofont-image',
    projectName: 'Feasibility study of Blatchford District Energy Project',
  },
  {
    image: '/img/project-images/REALIce.jpeg',
    iconClass: 'icofont-image',
    projectName: 'REALice pilot project at City Ice rinks.',
  },
  {
    image: '/img/project-images/Peter.jpeg',
    iconClass: 'icofont-image',
    projectName: 'Peter Hemingway pool, Edmonton',
  },
]

class Screenshots extends Component {
  state = {
    data: [],
  }
  componentDidMount() {
    this.setState({
      data: data,
    })
  }
  render() {
    return (
      <section className="section screenshots-area ptb_our_projects_100">
        <div className="container">
          <div className="row">
            <div className="col-12">
              {/* App Screenshot Slider Area */}
              <div className="app-screenshots">
                {/* Single Screenshot Item */}
                {this.state.data.map((item, idx) => {
                  return (
                    <div key={`sd_${idx}`} className="single-screenshot">
                      <img src={item.image} alt="" />
                      {/* Screenshots Overlay */}
                      <div className="screenshots-overlay">
                        <a href={item.image} data-fancybox="images">
                          <i className={item.iconClass} />
                        </a>
                      </div>
                      <p>{item.projectName}</p>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default Screenshots
