import React from 'react'

const ResponsiveImage = ({ imagePath, alt }) => {
  return (
    <div style={{ maxWidth: '100%', textAlign: 'center' }}>
      <img src={imagePath} alt={alt} style={{ width: '60%', height: 'auto' }} />
    </div>
  )
}

export default ResponsiveImage
