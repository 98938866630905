import React, { Component } from 'react'
import { projectData } from '../../properties/translations'
import { withTranslation } from 'react-i18next'
class ProjectSectionTitle extends Component {
  state = {
    data: {},
  }
  componentDidMount() {
    this.setState({
      data: projectData,
    })
  }
  render() {
    const { t } = this.props
    return (
      <section
        id="screenshots"
        className="section team-area ptb_our_projects_title_100"
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-10 col-lg-6">
              {/* Section Heading */}
              <div className="section-heading text-center">
                <h2 className="text-capitalize">{t('projectData.heading')}</h2>
                <p className="d-none d-sm-block mt-4">
                  {t('projectData.headingText')}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default withTranslation('translations')(ProjectSectionTitle)
