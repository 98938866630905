import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { footerData } from '../../properties/translations'
import LanguageContext from '../../configureContext'
import { Link } from 'react-router-dom'
class footer extends Component {
  state = {
    data: {},
    iconList: [],
    footerList_1: [],
    currentYear: new Date().getFullYear(),
  }
  componentDidMount() {
    this.setState({
      data: footerData,
      iconList: footerData.iconList,
      footerList_1: footerData.footerList_1,
    })
  }
  render() {
    const { t } = this.props
    return (
      <LanguageContext.Consumer>
        {(props) => {
          return (
            <footer id="footer" className="section footer-area footer-bg">
              {/* Footer Top */}
              <div className="footer-top ptb_100">
                <div className="container">
                  <div
                    className="row"
                    style={{
                      justifyContent:
                        props.state.activeLanguage === 'arabic'
                          ? 'flex-end'
                          : 'flex-start',
                    }}
                  >
                    <div className="col-12 col-sm-6 col-lg-3">
                      {/* Footer Items */}
                      <div className="footer-items">
                        {/* Logo */}
                        <a className="navbar-brand" href="/#">
                          <img
                            className="logo"
                            src={this.state.data.image}
                            alt=""
                          />
                        </a>
                        <p className="mt-2 mb-3">
                          <a
                            href="mailto:h.shahrukh@smcorp.org"
                            className="footer-white-text"
                          >
                            {t('footerData.text')}
                          </a>
                        </p>
                        <hr />
                        <p className=" mt-2 mb-3">
                          <a
                            href="mailto:s.barua@smcorp.org"
                            className="footer-white-text"
                          >
                            {t('footerData.email')}
                          </a>
                        </p>
                        {/* <p className="footer-white-text mt-2 mb-3">
                          {t('footerData.phone_text_1')}
                        </p>
                        <p className="footer-white-text mt-2 mb-3">
                          {t('footerData.phone_text_2')}
                        </p> */}
                        <p className=" mt-2 mb-3">
                          <a
                            href="tel:{t('footerData.phone_text_3')}"
                            className="footer-white-text"
                          >
                            {t('footerData.phone_text_3')}
                          </a>
                        </p>
                        <p className=" mt-2 mb-3">
                          <a
                            href="tel:{t('footerData.phone_text_4')}"
                            className="footer-white-text"
                          >
                            {t('footerData.phone_text_4')}
                          </a>
                        </p>
                        {/* <p className="footer-white-text mt-2 mb-3">
                          {t('footerData.social_media_text')}
                        </p> */}
                        {/* Social Icons */}
                        <div className="social-icons d-flex">
                          <a
                            className="facebook footer-white-text"
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.facebook.com/profile.php?id=100095054332081&mibextid=ZbWKwL"
                          >
                            <i className="fab fa-facebook-f" />
                          </a>
                          {/* <a
                            className="twitter"
                            target="_blank"
                            href="https://twitter.com/TThevisions?fbclid=IwAR3GuVU2wNLRk_qa9NVWm6wKpXVUHdNnU4DdpI1MXjSHIhzBw5EqX1lKPsg"
                          >
                            <i className="fab fa-twitter" />
                          </a> */}
                          {/* <a
                            target="_blank"
                            href="https://www.instagram.com/ta7seenvisions/?hl=en"
                          >
                            <i className="fab fa-instagram" />
                          </a> */}
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-3">
                      {/* Footer Items */}
                      <div className="footer-items">
                        {/* Footer Title */}
                        <h3 className="footer-title text-white mb-2">
                          {t('footerData.linkText_1')}
                        </h3>
                        <ul>
                          {this.state.footerList_1.map((item, idx) => {
                            return (
                              <li key={`flo_${idx}`} className="py-2">
                                <a
                                  className="nav-link scroll footer-white-text"
                                  href={item.goTo}
                                >
                                  {t(`footerData.footerList_1.${idx}.text`)}
                                </a>
                              </li>
                            )
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Footer Bottom */}
              <div className="footer-bottom">
                <div className="container">
                  <div className="row">
                    <div className="col-12">
                      {/* Copyright Area */}
                      <div className="copyright-area d-flex flex-wrap justify-content-center justify-content-sm-between text-center py-4">
                        {/* Copyright Left */}
                        <div className="copyright-left footer-white-text">
                          &copy; Copyrights {this.state.currentYear}{' '}
                          {t('footerData.copyrightText')}
                        </div>
                        <div className="copyright-left footer-white-text">
                          <Link to="/privacy-policy">Privacy Policy</Link>
                        </div>
                        {/* Copyright Right */}
                        <div className="copyright-right footer-white-text">
                          Made with <i className="icofont-heart-alt" /> By{' '}
                          <a className="footer-white-text" href="/#">
                            SM Corp Consulting Inc. Alberta, Canada
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </footer>
          )
        }}
      </LanguageContext.Consumer>
    )
  }
}

export default withTranslation('translations')(footer)
