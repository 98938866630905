import React, { Component } from 'react'
import { contactSectionData } from '../../properties/translations'
import { withTranslation } from 'react-i18next'
import emailjs from '@emailjs/browser'
require('dotenv')
const contactFormData = contactSectionData

class contactSection extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: {},
      bestWayToReach: 'Call',
      name: '',
      firmName: '',
      email: '',
      phone: '',
      message: '',
      formSubmitted: false,
      formEmailSent: false,
    }
    //creates a reference for your element to use
    this.contactRef = React.createRef()
    this.handleChange = this.handleChange.bind(this)
    this.submitForm = this.submitForm.bind(this)
  }

  componentDidMount() {
    this.setState({
      data: contactFormData,
    })
  }

  handleChange = (e) => {
    let { name, value } = e.target
    this.setState({
      [name]: value,
    })
  }

  submitForm = (event) => {
    event.preventDefault()
    if (
      this.state.bestWayToReach !== '' ||
      this.state.name !== '' ||
      this.state.firstName !== '' ||
      this.state.email !== '' ||
      this.state.phone !== '' ||
      this.this.state.message !== ''
    ) {
      const templateParams = {
        bestWayToReach: this.state.bestWayToReach,
        name: this.state.name,
        firmName: this.state.firmName,
        email: this.state.email,
        phone: this.state.phone,
        message: this.state.message,
      }

      emailjs
        .send(
          'service_ld1yu5p',
          'template_4hswaej',
          templateParams,
          'QgNXs-cE5g0tvHawz'
        )
        .then(
          (response) => {
            if (response.status === 200) {
              const notification = {
                title: 'Thanks!',
                message: 'You request has been submitted',
                type: 'success',
                insert: 'top',
                container: 'top-right',
                animationIn: ['animated', 'fadeIn'],
                animationOut: ['animated', 'fadeOut'],
              }

              this.props.notificationStore.addNotification({
                ...notification,
                container: 'top-right',
                dismiss: {
                  duration: 3000,
                },
              })
            }
          },
          (err) => {
            const notification = {
              title: 'Error',
              message: 'Something went wrong',
              type: 'error',
              insert: 'top',
              container: 'top-right',
              animationIn: ['animated', 'fadeIn'],
              animationOut: ['animated', 'fadeOut'],
            }

            this.props.notificationStore.addNotification({
              ...notification,
              container: 'top-right',
              dismiss: {
                duration: 3000,
              },
            })
          }
        )
    }

    this.setState({
      formSubmitted: true,
      bestWayToReach: 'Call',
      name: '',
      firmName: '',
      email: '',
      phone: '',
      message: '',
    })
  }

  // Note: this is using default_service, which will map to whatever
  // default email provider you've set in your EmailJS account.
  sendEmail(
    templateId,
    senderEmail,
    receiverEmail,
    name,
    bestWayToReach,
    firmName,
    email,
    phone,
    message,
    user
  ) {
    window.emailjs
      .send(
        'default_service',
        templateId,
        {
          senderEmail,
          receiverEmail,
          name,
          bestWayToReach,
          firmName,
          email,
          phone,
          message,
        },
        user
      )
      .then((res) => {
        this.setState({
          formEmailSent: true,
        })
      })
      // Handle errors here however you like
      .catch((err) => console.error('Failed to send feedback. Error: ', err))
  }

  render() {
    const { t } = this.props
    const { name, firmName, email, phone, message } = this.state
    return (
      <section
        id="contact"
        className="contact-area bg-gray ptb_100"
        ref={this.contactRef}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-10 col-lg-6">
              {/* Section Heading */}
              <div className="section-heading text-center">
                <h2 className="text-capitalize">
                  {t('contactSectionData.heading')}
                </h2>
                <p className="d-none d-sm-block mt-4">
                  {t('contactSectionData.headingText')}
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              {/* Contact Box */}
              <div className="contact-box text-center">
                {/* Contact Form */}
                <form id="contact-form">
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          name="name"
                          value={name}
                          onChange={this.handleChange}
                          placeholder={t('contactSectionData.Name')}
                          required="required"
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          name="firmName"
                          value={firmName}
                          onChange={this.handleChange}
                          placeholder={t('contactSectionData.FirmName')}
                          required="required"
                        />
                      </div>
                      <div
                        style={{
                          textAlign: 'left',
                          marginTop: '10px',
                          marginBottom: '10px',
                        }}
                      >
                        <label
                          style={{
                            marginRight: '5px',
                          }}
                          forHtml="bestWayToReach"
                        >
                          {' '}
                          {t('contactSectionData.BestWayToReach')}
                        </label>
                        <select
                          name="bestWayToReach"
                          id="bestWayToReach"
                          onChange={this.handleChange}
                        >
                          <option value={t('contactSectionData.call')}>
                            {t('contactSectionData.call')}
                          </option>
                          <option value={t('contactSectionData.email')}>
                            {t('contactSectionData.email')}
                          </option>
                        </select>
                      </div>
                      <div className="form-group">
                        <input
                          type="email"
                          className="form-control"
                          name="email"
                          value={email}
                          onChange={this.handleChange}
                          placeholder={t('contactSectionData.email')}
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="tel"
                          className="form-control"
                          name="phone"
                          value={phone}
                          onChange={this.handleChange}
                          placeholder={t('contactSectionData.phone')}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="form-group">
                        <textarea
                          className="form-control"
                          name="message"
                          value={message}
                          onChange={this.handleChange}
                          maxLength="500"
                          placeholder={t('contactSectionData.howCanWeServeYou')}
                          required="required"
                          defaultValue={''}
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <button
                        className="btn btn-bordered mt-3 mt-sm-4"
                        onClick={this.submitForm}
                      >
                        {t('contactSectionData.sendMessage')}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default withTranslation('translations')(contactSection)
