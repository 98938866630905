import React from 'react'

const LicenseSection = () => {
  return (
    <>
      <section id="screenshots" className="section team-area">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-10 col-lg-6">
              {/* Section Heading */}
              <div className="section-heading text-center">
                <h2 className="text-capitalize">Licenses and Awards</h2>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="container">
        <div className="row">
          <div className="col-md-4 col-sm-6">
            <div className="image-container">
              <img
                src="/img/project-images/L1.png"
                alt="license 1"
                className="img-fluid zoomable-image"
              />
            </div>
          </div>
          <div className="col-md-4 col-sm-6">
            <div className="image-container">
              <img
                src="/img/project-images/L2.png"
                alt="license 2"
                className="img-fluid zoomable-image"
              />
            </div>
          </div>
          <div className="col-md-4 col-sm-6">
            <div className="image-container">
              <img
                src="/img/project-images/L3.png"
                alt="license 3"
                className="img-fluid zoomable-image"
              />
            </div>
          </div>
          <div className="col-md-4 col-sm-6">
            <div className="image-container">
              <img
                src="/img/project-images/L4.png"
                alt="license 4"
                className="img-fluid zoomable-image"
              />
            </div>
          </div>
          <div className="col-md-4 col-sm-6">
            <div className="image-container">
              <img
                src="/img/project-images/L5.png"
                alt="license 5"
                className="img-fluid zoomable-image"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default LicenseSection
