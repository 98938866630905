import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
class welcomeSection extends Component {
  render() {
    const { t } = this.props
    return (
      <section
        id="home"
        className="section welcome-area bg-inherit d-flex align-items-center overlay-primary jarallax h-100vh pt-0"
        data-jarallax-video="https://www.youtube.com/watch?v=PhY7uAMKYg4"
      >
        <div className="container">
          <div className="row align-items-center justify-content-center">
            {/* Welcome Intro Start */}
            <div className="col-12 col-md-10 col-lg-8">
              <div className="welcome-intro text-center">
                <h1 className="text-white">
                  {t('welcomeSectionData.heading')}
                </h1>
                <p className="text-white mt-4 mb-5">
                  {t('welcomeSectionData.content')}
                </p>
                <p className="text-white mt-4 mb-5">
                  {t('welcomeSectionData.content2')}
                </p>
                <button
                  className="btn btn-bordered-white"
                  type="submit"
                  onClick={this.props.scrollToBottom}
                >
                  {t('welcomeSectionData.btnText')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default withTranslation('translations')(welcomeSection)
